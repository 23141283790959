:root ._fd-row{flex-direction:row;}
:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._gap-1481558245{gap:var(--space-6);}
:root ._fd-column{flex-direction:column;}
:root ._fs-1{flex-shrink:1;}
:root ._btc-2122800589{border-top-color:var(--borderColor);}
:root ._brc-2122800589{border-right-color:var(--borderColor);}
:root ._bbc-2122800589{border-bottom-color:var(--borderColor);}
:root ._blc-2122800589{border-left-color:var(--borderColor);}
:root ._btw-0px{border-top-width:0px;}
:root ._brw-1px{border-right-width:1px;}
:root ._bbw-0px{border-bottom-width:0px;}
:root ._blw-0px{border-left-width:0px;}
:root ._fg-1{flex-grow:1;}
:root ._h-initial{height:initial;}
:root ._mah-initial{max-height:initial;}
:root ._als-stretch{align-self:stretch;}
:root ._w-0px{width:0px;}
:root ._maw-0px{max-width:0px;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._transform-d0t1907327989{transform:translateY(0px) translateX(-0.5px);}